define("discourse/plugins/discourse-narrative-bot/initializers/new-user-narrative", ["exports", "discourse/lib/ajax", "discourse-common/lib/debounce", "discourse/lib/offset-calculator", "discourse/lib/is-element-in-viewport", "discourse/lib/plugin-api"], function (_exports, _ajax, _debounce, _offsetCalculator, _isElementInViewport, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PLUGIN_ID = "new-user-narrative";

  function _initialize(api) {
    var messageBus = api.container.lookup("service:message-bus");
    var currentUser = api.getCurrentUser();
    var appEvents = api.container.lookup("service:app-events");
    api.modifyClass("component:site-header", {
      pluginId: PLUGIN_ID,
      didInsertElement: function didInsertElement() {
        this._super.apply(this, arguments);

        this.dispatch("header:search-context-trigger", "header");
      }
    });
    api.modifyClass("controller:topic", {
      pluginId: PLUGIN_ID,
      _modifyBookmark: function _modifyBookmark(bookmark, post) {
        var _this = this;

        // if we are talking to discobot then any bookmarks should just
        // be created without reminder options, to streamline the new user
        // narrative.
        var discobotUserId = -2;

        if (post && post.user_id === discobotUserId && !post.bookmarked) {
          return (0, _ajax.ajax)("/bookmarks", {
            type: "POST",
            data: {
              post_id: post.id
            }
          }).then(function (response) {
            post.setProperties({
              "topic.bookmarked": true,
              bookmarked: true,
              bookmark_id: response.id
            });
            post.appEvents.trigger("post-stream:refresh", {
              id: _this.id
            });
          });
        }

        return this._super(bookmark, post);
      },
      subscribe: function subscribe() {
        var _this2 = this;

        this._super.apply(this, arguments);

        this.messageBus.subscribe("/topic/".concat(this.get("model.id")), function (data) {
          var topic = _this2.model; // scroll only for discobot (-2 is discobot id)

          if (topic.get("isPrivateMessage") && _this2.currentUser && _this2.currentUser.get("id") !== data.user_id && data.user_id === -2 && data.type === "created") {
            var postNumber = data.post_number;
            var notInPostStream = topic.get("highest_post_number") <= postNumber;
            var postNumberDifference = postNumber - topic.get("currentPost");

            if (notInPostStream && postNumberDifference > 0 && postNumberDifference < 7) {
              _this2._scrollToDiscobotPost(data.post_number);
            }
          }
        }); // No need to unsubscribe, core unsubscribes /topic/* routes
      },
      _scrollToDiscobotPost: function _scrollToDiscobotPost(postNumber) {
        (0, _debounce.default)(this, function () {
          var post = document.querySelector(".topic-post article#post_".concat(postNumber));

          if (!post || (0, _isElementInViewport.default)(post)) {
            return;
          }

          var viewportOffset = post.getBoundingClientRect();
          window.scrollTo({
            top: window.scrollY + viewportOffset.top - (0, _offsetCalculator.headerOffset)(),
            behavior: "smooth"
          });
        }, postNumber, 500);
      }
    });
    api.attachWidgetAction("header", "headerSearchContextTrigger", function () {
      if (this.site.mobileView) {
        this.state.skipSearchContext = false;
      } else {
        this.state.contextEnabled = true;
        this.state.searchContextType = "topic";
      }
    });

    if (messageBus && currentUser) {
      messageBus.subscribe("/new_user_narrative/tutorial_search", function () {
        appEvents.trigger("header:search-context-trigger");
      });
    }
  }

  var _default = {
    name: "new-user-narratve",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("service:site-settings");

      if (siteSettings.discourse_narrative_bot_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.7", _initialize);
      }
    }
  };
  _exports.default = _default;
});

